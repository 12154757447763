import * as React from "react";
import styled from "styled-components";
import { theme } from "../components/theme";
import Layout from "../components/Layout";
import { Meta } from "../components/Meta";
import { Helmet } from "react-helmet";

interface IProps {}

const Contact: React.FC<IProps> = () => {
  return (
    <Layout>
      <Meta />
      <Helmet>
        <title>kame's blog - Contact</title>
      </Helmet>

      <Container>
        <h1 style={{ width: "100%", textAlign: "center" }}>Contact</h1>

        <IFrame src="https://docs.google.com/forms/d/e/1FAIpQLSdG0DqS82sZml230LPlEdtEQTUJDNcz0J4cM7Fl-B3Xw3VW9A/viewform?embedded=true">
          読み込んでいます…
        </IFrame>
      </Container>
    </Layout>
  );
};

export default Contact;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 12px;

  width: 100vw;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: ${theme.width.body};
    padding: 0;
  }
`;

const IFrame = styled.iframe`
  width: 100%;
  height: 1165px;
  border: 0;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
`;
